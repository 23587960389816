.ant-layout.site {
    background: #fff  ;
    padding:0;
    margin:0;
}

.ant-layout{
    background: #fff;
    width: '100%';
    margin:0;
    padding: 0;
}

.ant-layout-content{
    background: #fff;
    margin:180px auto;
    width: 1140px !important;

}
.ant-layout-footer {
padding:0!important;
margin:0!important;
border-top:1px solid #DDD;
    .infoWrapper{
        background-color: #9fbbc340;
        padding-top:40px;
        .info{
            width:1140px;
            margin:auto;
            .ant-card{
                background-color: transparent;
                .ant-card-head{
                    min-height: 0;
                    text-align: left;
                    font-weight: 500;
                    letter-spacing: 1px;
                    color:@primary-color;
                    border:none;
                    .ant-card-head-title{
                        padding: 0;
                    }
                }
                .ant-card-body{
                    padding-top:12px;
                    text-align: left;
                    font-weight: 400;
                    ul{
                    padding-inline-start: 0px;
                    }
                    iframe{
                        border:none;
                    }
                }
            }
        }
    }
    .copyrightWrapper{
        background-color: #9FBBC320;
        .copyright{
            padding:20px 0;
            width:1140px;
            margin:auto;
            color: #999;
            font-weight: 700;
            font-family: 'ROBOTO';
        }
    }
}
.actionsWrapper{
    .ant-col{
        padding:0;
        margin:12px 0 6px 0px;;
        line-height: 0;
        div{
            display: flex;
            justify-content : right;
            align-content: stretch;
            padding-right:20px;
            .ant-btn{
                margin-left:10px;
            }
        }
    }
}
.ant-menu {
    line-height: 40px !important;
    .ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child{
        .ant-menu-title-content{
            a{  
                font-weight: bold;
                font-size: 14px;
                color:#333 !important;
            }
        } 
    }
    .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal{  
        .ant-menu-submenu-title{
            .ant-menu-title-content{
                font-weight: bold;
                font-size: 14px;
                color:#333 !important;
                a{
                    font-weight: bold;
                    font-size: 14px;
                    color:#333 !important;
                }
            } 
        }
    }

    .ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child{
        .ant-menu-title-content{
            a{
                font-weight: bold;
                font-size: 14px;
                color: @primary-color !important;
            }
        } 
    }
    .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected{
        .ant-menu-submenu-title{
            .ant-menu-title-content{
                a{
                    font-weight: bold;
                    font-size: 14px;
                    color:@primary-color !important;
                }
            } 
        }
    }
    
}




@primary-color: #0093AF;@link-color: #0093AF;@success-color: #00AFC1;@warning-color: #FFD124;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@layout-body-background: #fff;@layout-header-background: #fff;@layout-header-height: 200px;@font-family: Roboto;@layout-sider-background: #f6f7fb;@menu-bg: #f6f7fb;@menu-inline-submenu-bg: #f6f7fb;@menu-item-active-bg: rgba(1, 1, 161, 0.05);@menu-item-active-border-width: 0;@menu-item-vertical-margin: 0;@menu-item-boundary-margin: 0;@tabs-card-head-background: #f6f7fb;@form-item-margin-bottom: 5px;